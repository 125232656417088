/* eslint-disable */
// import * as yup from 'yup';
import { i18n } from '@/locales/i18n';
// import Roles from '@/security/roles';
import IdField from '@/shared/fields/id-field';
import StringField from '@/shared/fields/string-field';
// import StringArrayField from '@/shared/fields/string-array-field';
import BooleanField from '@/shared/fields/boolean-field';
import DateTimeField from '@/shared/fields/date-time-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import IntegerField from '@/shared/fields/integer-field';
// import ImagesField from '@/shared/fields/images-field';
// import DateTimeRangeField from '@/shared/fields/date-time-range-field';
// import GenericField from '@/shared/fields/generic-field';
import { GenericModel } from '@/shared/model/generic-model';

function label(name) {
  return i18n(`iam.fields.${name}`);
}
function enumeratorLabel(name, value) {
  return i18n(`product.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id'), { required: true }),
  fullName: new StringField('fullName', label('fullName'), { required: true }),
  phoneNumber: new StringField('phoneNumber', label('phoneNumber'), {
    required: true,
    matches: /^[0-9]/,
    max: 24,
  }),
  email: new StringField('email', label('email'), {
    required: true,
    max: 255,
  }),
  state: new StringField('state', label('state'), { required: true }),
  city: new StringField('city', label('city'), { required: true }),
  zipCode: new StringField('zipCode', label('zipCode'), { required: true }),
  familyMembersCount: new IntegerField('familyMembersCount', label('familyMembersCount'), { required: true }),
  underEightCount: new IntegerField('underEightCount', label('underEightCount'), { required: true }),
  overFiftyCount: new IntegerField('overFiftyCount', label('overFiftyCount'), { required: true }),
  isRemoved: new BooleanField('rememberMe', label('rememberMe')),

  type: new EnumeratorField('type', label('type'), [
    { id: 'member', label: enumeratorLabel('type', 'member') },
    { id: 'volunteer', label: enumeratorLabel('type', 'volunteer') },
    { id: 'organizer', label: enumeratorLabel('type', 'organizer') },
    { id: 'patron', label: enumeratorLabel('type', 'patron') }
  ], { required: true }),

  gender: new EnumeratorField('gender', label('gender'), [
    { id: 'male', label: enumeratorLabel('gender', 'male') },
    { id: 'female', label: enumeratorLabel('gender', 'female') }
  ], { required: true }),

  role: new EnumeratorField('role', label('role'), [
    { id: 'user', label: enumeratorLabel('role', 'user') },
    { id: 'admin', label: enumeratorLabel('role', 'admin') }
  ], { required: true }),

  status: new EnumeratorField('status', label('status'), [
    { id: 'pending', label: enumeratorLabel('status', 'pending') },
    { id: 'accepted', label: enumeratorLabel('status', 'accepted') },
    { id: 'rejected', label: enumeratorLabel('status', 'rejected') }
  ], { required: true }),
  
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdBy: new StringField('createdBy', label('createdBy')),
  updatedBy: new StringField('updatedBy', label('updatedBy')),
  // createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class UserModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
